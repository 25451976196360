<template>
    <div class="container">
        <!-- <span>问题反馈</span> -->
        <div class="feedBack">
            <el-form ref="form" :model="form" label-width="80px" :rules="rules">
                <el-form-item style="margin-top:35px" label="问题描述" prop="feedBackTitle">
                    <el-input v-model="form.feedBackTitle" placeholder="请输入问题标题（描述）"></el-input>
                </el-form-item>
                <el-form-item style="margin-top:35px" label="问题内容" prop="feedBackContent">
                    <el-input  type="textarea" :rows="5" v-model="form.feedBackContent" placeholder="请输入问题内容，以及您对梯到的建议、改善方案，请详细阐述"></el-input>
                </el-form-item>
                <el-form-item style="margin-top:35px" label="问题图片" prop="feedBackPic">
                    <el-upload
                        ref="upload"
                        action="https://www.ti-dao.com/v3/oss/common/upload?source=evaluate"
                        list-type="picture-card"
                        :limit="5"
                        :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove"
                        :on-success="handleAvatarSuccess"
                        :on-exceed="handleExceed"
                        :before-upload="beforeAvatarUpload">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog v-model="dialogVisible" width="800px">
                      <div class="img_div">
                        <img style="width:300px;height:300px" :src="dialogImageUrl" alt="Preview Image" />
                      </div>
                    </el-dialog>
                </el-form-item>
                <el-form-item>
                    <el-button type="danger" size="medium" @click="submitFeedBack('form')" style="width: 150px;border-radius: 8px">提 交</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
  import { getCurrentInstance } from 'vue';
  export default {
    data() {
      return {
        dialogVisible:false,
        dialogImageUrl:null,
        fileList:[],
        proxy:{},
        form: {
          feedBackTitle: null,
          feedBackContent: null,
          feedBackPic: null,
        },
        rules: {
          feedBackTitle: [
            { required: true, message: '请输入问题标题（描述）', trigger: 'blur' },
          ],
          feedBackContent: [
           { required: true, message: '请输入问题内容', trigger: 'blur' },
          ]
        },
        proxy:null,
      }
    },
    created(){
      const {proxy} = getCurrentInstance();
      this.proxy=proxy;
    },
    methods: {
      // 提交反馈
      submitFeedBack(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.proxy.$post('v3/business/front/feedBack/addFeedBack', this.form,{
              headers: {
                'Content-Type': 'multipart/form-data',
              }
            }).then(res => {
              if(res.state == 200) {
                this.$message({
                  message: '反馈成功',
                  type:'success'
                });
                this.form.feedBackTitle = null
                this.form.feedBackContent = null
                this.form.feedBackPic = null
                // 清空图片
                this.$refs.upload.clearFiles ()
              }
            })
          }
        })
      },
      // 上传成功
      handleAvatarSuccess(res,file) {
        this.fileList.push(res.data.url)
        this.form.feedBackPic = this.fileList.join(",")
      },
      // 预览图片
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.response.data.url;
        this.dialogVisible = true;
      },
      handleExceed() {
        this.$message({
          message: '最多上传5张图片',
          type: 'error'
        });
      },
      handleRemove(file,fileList) {
        this.form.feedBackPic = this.form.feedBackPic.replace(file.response.data.url,"").replace(",,",",")
      }
    }
  }
</script>

<style>
  .feedBack{
    width: 1000px;
    margin: 0 auto;
    margin-top: 15px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .img_div{
    text-align:center;
  }
</style>