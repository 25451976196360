<template>
    <div class="container">
        <div class="service_and_feedBack">
            <div class="icon_word" style="margin-bottom:10px">
                <div style="text-align:center"><img class="img" @click="toService" src="https://ti-daoshop.oss-cn-beijing.aliyuncs.com/apk_install/%E5%AE%A2%E6%9C%8D.png" /></div>
                <span  class="span_word" @click="toService">官方客服</span>
            </div>
            <div class="icon_word"  style="margin-bottom:10px">
                <div style="text-align:center"><img class="img" @click="toFeedBack" src="https://ti-daoshop.oss-cn-beijing.aliyuncs.com/apk_install/%E7%BC%96%E8%BE%91.png" /></div>
                <span  class="span_word" @click="toFeedBack" >反馈</span>
            </div>
            <div class="scrollBox" v-show="isShow">
                <div class="icon_word_top" >
                    <div style="text-align:center;margin-bottom:-3px"><img class="img" @click="toTop" src="https://ti-daoshop.oss-cn-beijing.aliyuncs.com/apk_install/%E7%BD%AE%E9%A1%B6.png" /></div>
                    <span class="span_word"  @click="toTop" >回到顶部</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        isShow:false,
        scrollTop:0,
        scrollTrigger:false,   
      }
    },
    mounted() {
        var that = this;
        window.addEventListener('scroll', function() {//监听滚动
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop >= 108) {
        //如果当前滚动位置大于您的所需要达到的高度则显示置顶元素，反之不显示
            that.isShow = true;
        } else {
            that.isShow = false;
        }
        });
    },

    created(){
    },
    methods: {
        getScrollTop(e) {
            let that = this; // 防止this指向问题
            let scrollTop = e.srcElement.scrollTop;
            this.scrollTop = scrollTop;
            // 判断距离顶部多少显示回到顶部图标
            if (scrollTop > 1) {
                that.isShow = true;
            } else {
                that.isShow = false;
            }
        },
        toFeedBack(){
            this.$router.push({path:'/feedBack'})
        },
        toTop() {
            var timer = null;
            cancelAnimationFrame(timer);
            timer = requestAnimationFrame(function fn(){
                var oTop = document.body.scrollTop || document.documentElement.scrollTop;
                if(oTop > 0){
                    document.body.scrollTop = document.documentElement.scrollTop = oTop - 50;
                    timer = requestAnimationFrame(fn);
                }else{
                    cancelAnimationFrame(timer);
                } 
            });
        },
        toService() {
            window.open('https://tb.53kf.com/code/client/9ee82f77a6ac4c9e68e6df99954f3cd58/1')
        }
    }
  }
</script>
<style  scoped>
    .container {
        display: flex;
        justify-content: end;
        z-index: 100000;
        position: relative;
    }
    .service_and_feedBack {
        /* 盒子样式 */
        border: 1px solid white;
        background-color: white;
        border-radius: 15px 0 0 15px;;
        /* box-shadow:2px 2px 2px 2px silver; */
        -webkit-box-shadow: -2px 0 30px 2px rgba(97, 105, 119, 0.18);
        -moz-box-shadow: -2px 0 30px 2px rgba(97, 105, 119, 0.18);
        box-shadow: -2px 0 30px 2px rgba(97, 105, 119, 0.18);
        font-size: 30px;
        width: auto;
        height:auto;
        /* display: flex; */
        /* flex-direction: column; */

        /* 鼠标滚动固定盒子 */
        position: fixed;
        top: 40%;
        right: 0%;
        /* float:right; */
        /* transform: translate(-50%,-50%); */

    }
    .img {
        margin-top: 20px;
        cursor: pointer;
        width: 25px;
    }
    .icon_word {
        font-size: 8px;
        padding: 10px;
        display: flex;
        flex-direction: column;
    }
    .icon_word_top {
        font-size: 8px;
        padding: 10px;
        display: flex;
        flex-direction: column;
    }
    .span_word{
        /* font-size:12px; */
        font-weight: 900;
        margin-top:10px;
        text-align:center; 
        cursor: pointer;
    }
</style>